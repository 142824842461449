//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSearchParams, useParams} from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { useDeleteGiftAid, useFetchGiftAids } from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { Button } from '@progress/kendo-react-buttons';
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";
import { ExcelExport } from '@progress/kendo-react-excel-export';

const StatefullGrid = WithState(Grid);

const breadcrumbItems = [{
  id: "gift_sources",
  text: " Gift Sources",
  URL: "/gift_sources",
  iconClass: 'k-i-cart'
}, 
{
  id: "gift_aids",
  text: "Gift Aids"
}];

const DepartmentsCell = (props) => {
  const { dataItem } = props;
  const departments = Array.isArray(dataItem.departments) ? dataItem.departments?.map(dept => dept.name).join(', ') : '';
  return (
    <td>
      {departments}
    </td>
  );
};

export const GiftAidsReportGrid = () => {
  const { giftSourceId } = useParams();
  const deleteMutation = useDeleteGiftAid();      
  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "name", dir: "asc" }
    ],
    filter: {
      logic: 'and',
      // filters: [
      //   { field: 'giftSourceId', operator: 'eq', value: giftSourceId }
      // ]
    },
    skip: 0,
    take: 10,
  });
  const navigate = useNavigate();
  const [gridData, setGridData] = React.useState([]);

  const _export = React.useRef(null);
  const excelExport = () => {    
    if (_export.current !== null) {      
      _export.current.save();
    }
  };  

  const enterView = (item) => {
    navigate(`/awardmgmt/giftsources/GS-18/giftaids/GA-${item.id}`);
  };

  const enterEdit = (item) => {     
    navigate(`/awardmgmt/giftsources/GS-18/giftaids/GA-${item.id}/edit`);
  }  

  const navigateToHistory = (dataItem) => {
    navigate(`/awardmgmt/giftaidshistory?filter=id~eq~${dataItem.id}&sort=modifiedAt-desc&page=1&pageSize=10`);
  }

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      <ViewCommandCell {...props} enterView={enterView}  />
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
      { <Button title="History" icon="clock-arrow-rotate" themeColor={'secondary'} 
                    onClick={ () => navigateToHistory(props.dataItem)} type="button">           
                    </Button>
                  }
    </td>
  );

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );  

  return (
    <>      
       <MyBreadcrumb breadcrumbItems={[          
            // { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10` },
            { id: "giftaids", text: `Gift Aids` },            
          ]} 
        />
       <div style={{margin:'10px 0px'}}>
       {/* <GiftSourceView giftSourceId={giftSourceId} /> */}
      </div>         
      <ExcelExport data={gridData.data ?? []} ref={_export}>
        <StatefullGrid
          apiDataSource={useFetchGiftAids}
          filterOperators={filterOperators}
          sortable={true}        
          resizable={true}
          // data={d => d.map((item) => ({
          //   ...item,
          //   inEdit: item.srsNumber === editID,
          // }))}
          minResizableWidth={30}                        
          dataState={gridDataState}
          gridData={gridData}
          setGridDataState={setGridDataState}
          setGridData={setGridData}
          updateURLSearchParams = {false}        
        > 
        <GridToolbar>       
            {/* <Button title="Add new" icon="plus" themeColor={'primary'} onClick={() => navigate('new')} type="button">
                Gift Aid
            </Button> */}
             <div className="export-excel-container" style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}>
                  <Button title="Export Excel" style={{float:'right'}}  icon="download" themeColor={'secondary'} type="button" onClick={excelExport}>                
                  </Button>
              </div>
          </GridToolbar>
          <GridColumn field="id" title="Id" filterable={true}  filter="numeric"  cell={(props) => (<td> GA-{props.dataItem.id}</td>) } width={"160px"} />       
          {/* <GridColumn field="giftSourceId" title="giftSourceId" filterable={true} width={"160px"} />        */}
          <GridColumn field="name" title="Name" filterable={true}  width={200}  />    
          <GridColumn field="type" title="Type" filterable={true}  width={200} />         
          <GridColumn field="costCenterCode" title="Cost Center Code" filterable={true} width={200}  />     
          <GridColumn field="aidId" title="Aid Id" filterable={true} width={200}  />     
          <GridColumn field="accountNumber" title="Account Number" filterable={true}  width={200} />                 
          <GridColumn field="departments" title="Departments"  cell={DepartmentsCell} filterable={true} />     
          <GridColumn field="modifiedAt" title="Modified At" filter="date" format="{0:MM/dd/yyyy}" width={"250px"} />
          {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}
                          
          <GridColumn cell={RecordCombinedCommandCell} filterable={false}  width={"200px"}/>

        </StatefullGrid>
      </ExcelExport>
    </>
  );
};
