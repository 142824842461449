//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../components/withState.js";
import { useFetchUsers } from "../../services/userService";
import { useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { filterBy } from "@progress/kendo-data-query";
import {
  Filter,
  Operators,
  TextFilter,
  NumericFilter,
  BooleanFilter,
} from "@progress/kendo-react-data-tools";


const StatefullGrid = WithState(Grid);

export const Users = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };


  const enterEdit = (item) => {
    navigate(`${item.id}/edit&step=0`);
  };

  const enterView = (item) => {
    navigate(`${item.id}`);
  };


  const EditCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterEdit(props.dataItem)}
        >
          <span className="k-icon k-i-edit"></span>  
          Edit
        </button>
      </td>
    );
  };

  const ViewCommandCell = (props) => {
    return (
      <td>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterView(props.dataItem)}
        >
          <span className="k-icon k-i-hyperlink-open"></span>  
          View
        </button>
      </td>
    );
  };

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );  

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "lastName", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });
  const navigate = useNavigate();
  return (
    <>      
      <div style={{margin:'10px 0px'}}>        
        <Link className="btn btn-primary" role="button" to="import"> Import User </Link>              
      </div>      
      <StatefullGrid
        apiDataSource={useFetchUsers}
        filterOperators={filterOperators}
        sortable={true}
        
        resizable={true}      
        minResizableWidth={30}        
        //onRowClick={NavigateToCourseEvaluate}
        dataState={gridDataState}
        setGridDataState={setGridDataState}        
        updateURLSearchParams = {true}
      >
        {/* <GridColumn field="id" title="id" filterable={false} /> */}            
        <GridColumn field="employeeId" title="University Id" filterable={true} width={"200px"} />
        <GridColumn field="email" title="Email" filterable={true} width={"200px"} />
        <GridColumn field="firstName" title="First Name" width={"200px"} filterable={true} />                
        <GridColumn field="lastName" title="Last Name" filterable={true} width={"200px"} />               
        <GridColumn field="userClaims" title="Claims" filterable={true} width={"200px"} 
          sortable={false}
          filterCell={(props) => {}}
          cell={(props) => {
            return (
              <td>
                <ul className="list-group">
                  {props.dataItem.userClaims.map((claim, index) => (                    
                    <li key={index}>
                      {claim.claimValue}                      
                    </li>
                  ))}
                </ul>
              </td>
            );
          }}
        />             
        <GridColumn field="userRoles" title="Roles" filterable={true} width={"200px"}           
          sortable={false}
          filterCell={(props) => {}}
          cell={(props) => {
            return (
              <td>
                <ul className="list-group">
                  {props.dataItem.userRoles.map((userRole, index) => (                    
                    <li key={index}>
                      {userRole.role.name}                    
                    </li>
                  ))}
                </ul>
              </td>
            );
          }}
        />     
        {/* <GridColumn cell={RecordViewCommandCell} filterable={false}  width={"110px"}/>
        <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/>         */}
      </StatefullGrid>
    </>
  );
};
