import * as React from "react";
import { AutoComplete, AutoCompleteChangeEvent } from "@progress/kendo-react-dropdowns";
import { useQueryClient } from "@tanstack/react-query";
import { useFetchStudents } from "../../../services/awardMgmgtService";



export const StudentAutoCompleteCell = (props) => {

  
  const [data, setData] = React.useState([]);  
  const [studentIdToStudent, setStudentIdToStudent] = React.useState([]);  
  const [value, setValue] = React.useState('');  
  const [query, setQuery] = React.useState('1');
  const { data: students, status : studentsStatus, isSuccess : studentsIsSuccess, isLoading, isError : studentsisError, error: studentsError, refetch: refetchStudents } = useFetchStudents(query);  
  
  React.useEffect(() => {        
    setData(Array.isArray(students) ? students?.map(student => `${student.uid} - ${student?.firstName}, ${student?.lastName}`) : []); 
    setStudentIdToStudent(Array.isArray(students) ? students?.reduce((acc, student) => {
      acc[`${student.uid} - ${student?.firstName}, ${student?.lastName}`] = student;
      return acc;
    }, {}) : []);    
    }, [students]);

  const queryClient = useQueryClient();

  const handleChange = (e) => {                    
    // make api call using useFetchStudents 
    // update the data
    // Instead, do this:
    const student = studentIdToStudent[e.target.value];
    //debugger;
    if (props.onChange) {
      props.onChange({
        dataItem: props.dataItem,
        field: 'student',
        syntheticEvent: e.syntheticEvent,
        value: student,
      });
      // props.onChange({
      //   dataItem: props.dataItem,
      //   field: 'studentFirstName',
      //   syntheticEvent: e.syntheticEvent,
      //   value: student?.firstName,
      // });
      // props.onChange({
      //   dataItem: props.dataItem,
      //   field: 'studentLastName',
      //   syntheticEvent: e.syntheticEvent,
      //   value: student?.lastName,
      // });
      // props.onChange({
      //   dataItem: props.dataItem,
      //   field: 'studentDescription',
      //   syntheticEvent: e.syntheticEvent,
      //   value: student?.description,
      // });
    }
    setQuery(e.target.value);
    refetchStudents();
                        
  };
  
  const { dataItem } = props;  
  const field = props.field || "";
  const id = dataItem[field] === null ? "" : dataItem[field];  
  //const dataValue = id;
  const dataValue = `${dataItem['studentUID']} - ${dataItem['studentFirstName']}, ${dataItem['studentLastName']}`;

  //debugger;

  return (
    <td>            
      {dataItem.isNew ? (
        <AutoComplete            
          name="studentUID"        
          onChange={handleChange}
          loading={isLoading}
          //value={props.cells.find((c) => c.id === id)}
          data={data}                    
        />
      ) : dataValue }
    </td>
  );
};   