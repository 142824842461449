import * as React from 'react';
import withRouter from './withRouter'
import { AppBar, AppBarSection, AppBarSpacer, Avatar, Drawer, DrawerContent } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import {ProfileContent} from  "./ProfileContent";
import { GlobalLoader } from "./GlobalLoader";
import { RolesAuthRoute } from "./RolesAuthRoute";
import { useUserMyRoles } from "../services/userService";
import ApiStatus from "../components/ApiStatus";
import { Loader } from '@progress/kendo-react-indicators';
import {getUrlWithoutQuery, convertTextToClassId} from "../utils/componentUtils"
let kendokaAvatar = '//www.telerik.com/account/images/AvatarImages/default.gif';

const DrawerRouterContainer = props => {
  const isAuthenticated = useIsAuthenticated();
  const [expanded, setExpanded] = React.useState(localStorage.getItem("menuExpanded") === "false" ? false : true);
  const [menuItems, setMenuItems] = React.useState([
  {
    id:1,
    text: 'Home',    
    icon: 'k-i-home',
    selected: true,
    route: '/',
    roles: ['Admin','AnyStudent', 'Employee']
  },
  {
    id:11,
    text: 'My Account',    
    icon: 'k-i-user',
    selected: true,
    route: '/myaccount',
    roles: ['Admin','Employee']
  },
  
  // {
  //   separator: true
  // }, 
  {
    id:2,
    text: 'Trigger Events',
    icon: 'k-i-wrench',
    route: '/triggerevents?sort=triggerAt-desc&page=1&pageSize=10',
    roles: ['Admin','Manager']
  }, 
  // {
  //   separator: true,  
  // },
  // {
  //   id:3,
  //   text: 'New Triger',
  //   // parentId: 2,
  //   icon: 'k-i-layout-1-by-4',
  //   route: '/triggerevents/new',
  //   roles: ['Admin']
  // },  
  {
    id:5,
    text: 'Scholarships',
    icon: 'k-i-pencil',
    route: '/scholarships?sort=createdAt-desc&page=1&pageSize=10',
    roles: ['Admin']
  },
  {
    id:18,
    text: 'My First Year Preferences',
    icon: 'k-i-template-manager',    
    route: '/firstyearpreferences/new',  
    roles: ['LawNET Development', 'Manager'],
    claims:['LawNET Development', '1L Students']
  },
  {
    id:7,
    text: 'First Year Course Preferences',
    icon: 'k-i-template-manager',
    route: '/firstyearpreferences?sort=createdAt-asc&page=1&pageSize=10',
    roles: ['Admin', 'Manager']
  },
  {
    id:6,
    text: 'Account Activation',
    icon: 'k-i-check-circle',    
    route: '/accountactivation',  
    roles: ['Admin']  
  },
  {
    id:8,
    text: 'Users',
    icon: 'k-i-tell-a-friend',
    route: '/users?sort=lastName-asc&page=1&pageSize=10',
    roles: ['Admin']
  }, 
  {
    id:8,
    text: 'Enrollment Spreadsheet',
    icon: 'k-i-file-txt',    
    route: '/enrollments/enrollmentspreadsheet',  
    roles: ['Admin'],
    claims:['LawNET Development']
  },
  {
    id:7,
    text: 'Reports',
    icon: 'k-i-info-circle',    
    route: '/reports/spreadsheet',  
    roles: ['Admin'],
    claims:['LawNET Development']
  },
  {
    id:1128,
    text: 'separator',
    separator: true,    
    roles: ['Admin'],
    claims:['LawNET Development']
  },{
    id:118,
    text: 'Departments',
    icon: 'inherited',    
    route: '/awardmgmt/departments',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_DepartmentMember', 'AwardMgmt_FinancialAid', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },  
  {
    id:1189,
    text: 'Gift Sources',
    icon: 'k-i-cart',    
    route: '/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_FinancialAid', 'AwardMgmt_ExternalAffairs', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },  
  {
    id:11891,
    text: 'Gift Aids',
    icon: 'k-i-plus-circle',    
    route: '/awardmgmt/giftaids?sort=createdAt-desc&page=1&pageSize=10',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_FinancialAid', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },  
  {
    id:118912,
    text: 'Projections',
    icon: 'k-i-formula-fx',    
    route: '/awardmgmt/projections',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_DepartmentMember', 'AwardMgmt_FinancialAid', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },
  {
    id:11891,
    text: 'Awards',
    icon: 'k-i-dollar',    
    route: '/awardmgmt/awards',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_DepartmentMember', 'AwardMgmt_FinancialAid', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },
  {
    id:118,
    text: 'Cost Centers',
    icon: 'k-i-more-vertical',    
    route: '/awardmgmt/costcenters',  
    roles: ['AwardMgmt_Admin', 'AwardMgmt_FinancialAid', 'AwardMgmt_Finance'],
    claims:['LawNET Development']
  },
  {
    id:11289,
    text: 'separator',
    separator: true,    
    roles: ['LawNET Development', 'AwardMgmt_DepartmentMember', 'AwardMgmt_FinancialAid'],
    claims:['LawNET Development']
  },
  {
    id:4,
    text: 'Settings',
    icon: 'k-i-gear',
    route: '/settings',
    roles: ['Admin', 'Manager']
  },  
  {
    id:8,
    text: 'Version',
    icon: 'k-i-info-circle',    
    route: '/version',  
    roles: ['Admin'],
    claims:['LawNET Development']
  }    




  //firstyearelectivepreference

  // ,{
  //   id:5,
  //   text: 'Donor Scholarship',
  //   icon: 'k-i-menu',
  //   route: '/scholarship/32f05977-93f4-41f9-a67e-4bfc482544ff/edit&step=0',
  //   roles: ['Admin']
  // }
  // ,{
  //   id:5,
  //   text: 'Donor Scholarship 2',
  //   icon: 'k-i-menu',
  //   route: '/scholarship/32f05977-93f4-41f9-a67e-4bfc482544ff/edit&step=2',
  //   roles: ['Admin']
  // }

  //{
  //   separator: true
  // }, {
  //   text: 'Breadcrumb',
  //   icon: 'k-i-arrow-chevron-right',
  //   route: '/breadcrumb'
  // }, {
  //   separator: true
  // }, {
  //   text: 'Card',
  //   icon: 'k-i-image',
  //   route: '/card'
  // }, {
  //   separator: true
  // }, {
  //   text: 'GridLayout',
  //   icon: 'k-i-grid-layout',
  //   route: '/gridlayout'
  // }, {
  //   separator: true
  // }, 
  
  // , {
  //   separator: true
  // }, {
  //   text: 'PanelBar',
  //   icon: 'k-i-layout-side-by-side',
  //   route: '/panelbar'
  // }, {
  //   separator: true
  // }, {
  //   text: 'Splitter',
  //   icon: 'k-i-arrows-resizing',
  //   route: '/splitter'
  // }, {
  //   separator: true
  // }, {
  //   text: 'StackLayout',
  //   icon: 'k-i-layout-stacked',
  //   route: '/stacklayout'
  // }, {
  //   separator: true
  // }, {
  //   text: 'Stepper',
  //   icon: 'k-i-list-numbered',
  //   route: '/stepper'
  // }, {
  //   separator: true
  // }, {
  //   text: 'TabStrip',
  //   icon: 'k-i-thumbnails-up',
  //   route: '/tabstrip'
  // },
  
  
  ]);  

  const { data, status, isSuccess, isLoading } = useUserMyRoles();      

  if(isLoading) return  <div className='centered'><Loader size="large" type='converging-spinner' /></div>;
  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;  
  if (!data) return <div> Not found.</div>;    
    
  const handleClick = () => {
    localStorage.setItem("menuExpanded", !expanded);
    setExpanded(!expanded);
  };
  const onSelect = e => {           
    props.router.navigate(e.itemTarget.props.route);        
  };

  const setSelectedItem = pathName => {    
    let currentPath = menuItems.find(item => getUrlWithoutQuery(item.route) === pathName);
    if (currentPath?.text) {
      return currentPath.text;
    }
  };  
  const selected =  setSelectedItem(props?.router?.location?.pathname);  
    
  const userMenuItems = menuItems.filter(item => 
        data?.roles?.some(userRole => item.roles?.includes(userRole)) || data?.claims?.some(userClaim => item.claims?.includes(userClaim)))
          .map(item => ({...item, selected: item.text=== selected}));

                                    
  return <React.Fragment>
      <AppBar positionMode='sticky'>
        <AppBarSection>        
          <Button icon="layout" fillMode="flat" onClick={handleClick} />          
        </AppBarSection>

        <AppBarSection>
          <a href="/"><img alt="UCLA Law" height={60} src="/header.png"></img></a>
          <GlobalLoader />    
        </AppBarSection>
        <AppBarSpacer />     

        <AppBarSection>                   
          <ProfileContent />
          <Avatar type="image"> <img src={kendokaAvatar} /> </Avatar>
          <ul className='navLinks'>
            { isAuthenticated ? <SignOutButton /> : <SignInButton /> }
          </ul>               
           <AppBarSpacer />                   
        </AppBarSection>
      </AppBar>                  
        <Drawer 
        expanded={expanded} mode="push" position="start" mini={true} 
        items={userMenuItems} 
        onSelect={onSelect}
        >
     
        <DrawerContent>                                           
          {
            menuItems.some(item => item.text === selected) ?
            menuItems.map(item => {                   
            return (item.text === selected) &&             
              <RolesAuthRoute roles={item.roles} claims={item.claims}  key={item.text}>
                <div className="content content-page" id={convertTextToClassId(item.text)}>                                                                    
                                      {props.children}
                                  </div>
             </RolesAuthRoute>
            }) : 
          <div className="content" key={'test'}>                                                                    
            {props.children}
          </div>        
        }
        </DrawerContent>
      </Drawer>      
      
      <style>{`              
              .navLinks {
                font-size: 14px;
                list-style-type: none;
                padding: 0;
                margin: 0;
                display: flex;
              }
              .navLinks  li {
                  margin: 0 10px;
              }
              .navLinks li:hover {
                  cursor: pointer;
                  color: #84cef1;
              }
              .k-drawer-content{
                height:100%;
                overflow-y:auto;
                padding: 20px 0px 150px 0px;
              }
              `}
        </style>
    </React.Fragment>;
};
export default withRouter(DrawerRouterContainer);