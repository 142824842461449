import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const DropDownCell = (props) => {

  const handleChange = (e) => {
    if (props.onChange) {
      props.onChange({
        dataIndex: 0,
        dataItem: props.dataItem,
        field: props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.target.value.id,
      });
    }
  };

  const { dataItem } = props;  
  const field = props.field || "";
  const id = dataItem[field] === null ? "" : dataItem[field];  
  const dataValue = id != null && props.cells !== null ? props.cells?.find((c) => c.id === id).text : id;

  return (
    <td>            
      {dataItem.isNew || (props.editable && dataItem.inEdit) ? (
        <DropDownList          
          onChange={handleChange}
          value={props.cells.find((c) => c.id === id)}
          data={props.cells}          
          textField="text"
        />
      ) : dataValue }
    </td>
  );
};   