import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchGiftAid, useUpdateGiftAid } from "../../../../services/awardMgmgtService";
import { GiftAidForm } from "./GiftAidForm";
import ValidationSummary from "../../../../components/ValidationSummary";
import ApiStatus from "../../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../../components/MyBreadcrumb";

export const GiftAidEdit = (props) => {
  const { giftAidId, giftSourceId } = useParams();
  if (!giftAidId) throw Error("Trigger Event id not found");

  const eventMutation = useUpdateGiftAid(giftAidId);
  const { data: data, status : status, isSuccess : isSuccess, isError : isError, error: error } = useFetchGiftAid(giftAidId);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;      

  return (
    <>
     <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10` },
            { id: "giftaids", text: `GS-${giftSourceId}`, url: `/awardmgmt/giftsources/GS-${giftSourceId}/giftaids` },            
            { id: "giftaidView", text: `${data?.name}`, url: `/awardmgmt/giftsources/GS-${giftSourceId}/giftaids/GA-${giftAidId}` }   ,
            { id: "giftaid", text: `edit` }           
          ]} 
        />
      <GiftAidForm
        data={data}
        eventMutation={eventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
