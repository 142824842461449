//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSearchParams, useParams} from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { useDeleteGiftAid, useFetchDepartmentGiftAids, useFetchGiftAids } from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { Button } from '@progress/kendo-react-buttons';
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";
import { provideIntlService } from '@progress/kendo-react-intl';
import { GiftSourceView } from "../giftsources/GiftSourceView.jsx";

const StatefullGrid = WithState(Grid);

const breadcrumbItems = [{
  id: "gift_sources",
  text: " Gift Sources",
  URL: "/gift_sources",
  iconClass: 'k-i-cart'
}, 
{
  id: "gift_aids",
  text: "Gift Aids"
}];

const DepartmentsCell = (props) => {
  const { dataItem } = props;
  const departments = Array.isArray(dataItem.departments) ? dataItem.departments?.map(dept => dept.name).join(', ') : '';
  return (
    <td>
      {departments}
    </td>
  );
};

const NegativeAmountCell = (props) => {
  const { dataItem, field } = props;

  // 1. Detect if this row is a group header row by checking if it has 'items' or a group `field`.
  const isGroupHeader = dataItem?.items !== undefined && dataItem?.field !== undefined;
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  if (isGroupHeader) {
    // 2A. This is a group header row. We typically look up the aggregated value.
    // For example, if you're using the "sum" aggregate:
    const aggregatedValue = dataItem.aggregates[field]?.sum ?? 0;

    const textColor = aggregatedValue < 0 ? 'red' : 'inherit';

    return "";
  } else {
    // 2B. This is a normal data row
    const value = dataItem[field];
    const textColor = value < 0 ? 'red' : 'inherit';

    if(value == null) return ""; 

    return (
      <td style={{ color: textColor }}>
        {currencyFormatter.format(value)}
      </td>
    );
  }
};

export const GiftAidsProjectionsGrid = ({departmentId}) => {
  //const { giftSourceId } = useParams();
  const deleteMutation = useDeleteGiftAid();      
  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      //{ field: "name", dir: "asc" }
    ],
    // filter: {
    //   logic: 'and',
    //   filters: [
    //    //{ field: 'departments', operator: 'contains', value: departmentId }
    //   ]
    // },
    group: [{ field : 'name' }],
    skip: 0,
    take: 10,
    departmentId: departmentId
  });

  const [gridData, setGridData] = React.useState([]);

  const navigate = useNavigate();

  const enterView = (item) => {
    navigate(`GA-${item.id}`);
  };

  const enterEdit = (item) => {     
    navigate(`GA-${item.id}/edit`);
  }  

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      <ViewCommandCell {...props} enterView={enterView}  />
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
    </td>
  );

  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );  

  return (
    <>            
       <div style={{margin:'10px 0px'}}>
       {/* <GiftSourceView giftSourceId={giftSourceId} /> */}
      </div>           
      <h5>GiftAids Projections</h5>    
      <StatefullGrid        
        apiDataSource={useFetchDepartmentGiftAids}
        filterOperators={filterOperators}
        sortable={true}        
        
        resizable={true}
        expandField="expanded" 
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        groupable={true}
        group={gridDataState.group}
        minResizableWidth={30}                        
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        //setGridData={setGridData}
        updateURLSearchParams = {false}        
      > 
       {/* <GridToolbar>       
          <Button title="Add new" icon="plus" themeColor={'primary'} onClick={() => navigate('new')} type="button">
              Gift Aid
          </Button>          
        </GridToolbar> */}
        {/* <GridColumn field="prefixedId" title="ID" filterable={true} width={"160px"} />        */}
        {/* <GridColumn field="giftSourceId" title="giftSourceId" filterable={true} width={"160px"} />        */}
        {/* <GridColumn field="id" title="Id" filterable={true} />     */}
        {/* <GridColumn field="prefixedId" title="Id" filterable={false} width={70}/>     */}
        {/* <GridColumn field="name" title="Name" filterable={false} />   */}
        <GridColumn field="type" title="Type" filterable={false}  width={180} />  
        <GridColumn field="foundationNumber" title="FoundationNumber" filterable={false}  width={140} />  
        <GridColumn field="fundNumber" title="FundNumber" filterable={false}  width={110} />  
        <GridColumn field="aidId" title="AidId" filterable={false}  width={70} />  

        <GridColumn field="projectionYear" title="Year" filterable={false}  width={60} />  
        <GridColumn field="projectionAmount" title="Projected Amount" filterable={false}  format="{0:c}" width={150} />  
        <GridColumn field="usedAmount" title="Used Amount"  filterable={false} format="{0:c}" width={120}/>  
        <GridColumn field="remainingAmount" title="Remaining Amount" cell={NegativeAmountCell} filterable={false}  format="{0:c}" width={160} />  
        
        {/* <GridColumn field="costCenterCode" title="Cost Center Code" filterable={true} />      */}
        {/* <GridColumn field="aidId" title="Aid Id" filterable={true} />              */}
        {/* <GridColumn field="modifiedAt" title="Modified At" filter="date" format="{0:MM/dd/yyyy}" width={"250px"} /> */}
        {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}
                        
        {/* <GridColumn cell={RecordCombinedCommandCell} filterable={false}  width={"200px"}/> */}

      </StatefullGrid>
    </>
  );
};
