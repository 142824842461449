import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useCreateGiftSource } from "../../../services/awardMgmgtService";
import { GiftSourceForm } from "./GiftSourceForm";
import ValidationSummary from "../../../components/ValidationSummary";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb";

export const GiftSourceNew = (props) => {
  const createEventMutation = useCreateGiftSource();
  const data = {    
    createdAt: new Date(new Date().getTime() + 1 * 60000),
  };

  return (
    <>
      <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10` },            
            { id: "new", text: "new" },            
          ]} 
      />
      <GiftSourceForm
        data={data}
        eventMutation={createEventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
