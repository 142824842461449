import * as React from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { FormInput, FormDatePicker } from "../../components/form-components";
import { requiredValidator, fieldValidator } from "../../components/validators";
import { DateInput } from "@progress/kendo-react-dateinputs";
import ValidationSummary from "../../components/ValidationSummary";
import { getValue } from "@testing-library/user-event/dist/utils";
import ApiStatus from "../../components/ApiStatus";
import { PageLoader } from "../../components/PageLoader";
import { PageMutating } from "../../components/PageMutating";
import { DateTime, Settings } from "luxon";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";


export const ImportUsersForm = ({ data,  eventMutation, isNew = false }) => {      

  const {isLoading } = eventMutation;    
  const [formState, setFormState] = React.useState( { groupOptions: data, groupIds:[] });  


  const onDropDownChange = (e) => {    
    setFormState({ ...formState, groupIds: e.target.value });    
  }
  const handleFilterChange = (e) => {        
    setFormState({ ...formState, groupOptions: data.filter((i) => i.displayName.toLowerCase()?.includes(e.filter.value?.toLowerCase())) });    
    //setUserOptions(userOriginalOptions.filter((i) => i.email.startsWith(e.filter.value)));    
  };

  return (
    <>        
    <Form
      onSubmit={(d) => 
        {                    
          return eventMutation.mutate(formState?.groupIds)}}
      
      initialValues={data}                        
      render={(formRenderProps) => (
        <FormElement
          style={{
            maxWidth: 650,
          }}
        >
          
          <fieldset className={"k-form-fieldset"}>
            <legend className={"k-form-legend"}>
              Please fill in the following information:
            </legend>
            {eventMutation.isError && <ValidationSummary error={eventMutation.error} />}
            {formRenderProps.visited &&
              formRenderProps.errors &&
              formRenderProps.errors.VALIDATION_SUMMARY && (
                <div className={"k-messagebox k-messagebox-error"}>
                  {formRenderProps.errors.VALIDATION_SUMMARY}
                </div>
              )}                 

              <div className="col-xs-12 col-sm-7 example-col">        
                <Field name={"groups"} 
                      component={MultiSelect}  
                      textField="displayName"
                      dataItemKey="id"   
                      data={formState.groupOptions}     
                      filterable={true}
                      onFilterChange={handleFilterChange}
                      onChange={(e) => onDropDownChange(e)}                                              
                      label={" Group Names"}  />
              </div>                                               
            
          </fieldset>
          <div className="k-form-buttons">
            <button
              type={"submit"}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              disabled={!formRenderProps.allowSubmit || isLoading}
            >              
              Submit
            </button>            
           <PageMutating/>
          </div>
        </FormElement>
      )}
    />
    </>
  );
};

