//import './CourseEvaluations.css';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DateTime, Settings } from "luxon";
import {useFetchDepartments, useDeleteDepartment} from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";

const StatefullGrid = WithState(Grid);

export const DepartmentsGrid = () => {
  
  const deleteMutation = useDeleteDepartment();    

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "name", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);

  const navigate = useNavigate();  

  const enterView = (item) => {
    navigate(`${item.id}`);
  };

  const RecordCombinedCommandCell = (props) => (
    <td className="record-combined-command-cell">
      <ViewCommandCell {...props} enterView={enterView}  />
      <EditCommandCell {...props} enterEdit={enterEdit} />      
      <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
    </td>
  );

  const enterEdit = (item) => {     
    navigate(`${item.id}/edit`);
  }  

  return (
    <>      
       <MyBreadcrumb breadcrumbItems={[          
            { id: "departments", text: "Departments", iconName: 'inherited', url: `/awardmgmt/departments` },            
          ]} 
        />
       <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary"  onClick={() => navigate('new') }> New </button>      
        {/* <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="courses?sort=srsNumber-asc&page=1&pageSize=10"> Course </Link>       */}
        {/* <Link className="btn btn-primary" style={{marginLeft:'10px'}} role="button" to="enrollments"> Enrollments </Link>       */}
      </div>    
      <StatefullGrid
        apiDataSource={useFetchDepartments}
        filterOperators={filterOperators}
        sortable={true}
        
        resizable={true}
        // data={d => d.map((item) => ({
        //   ...item,
        //   inEdit: item.srsNumber === editID,
        // }))}
        minResizableWidth={30}                        
        dataState={gridDataState}
        gridData={gridData}
        setGridDataState={setGridDataState}
        setGridData={setGridData}
        updateURLSearchParams = {true}        
      >        
        <GridColumn field="name" title="Name" filterable={true} />                        
        <GridColumn field="description" title="Description" filterable={true} />                                        
        <GridColumn cell={RecordCombinedCommandCell} filterable={false} width={"180px"} />

      </StatefullGrid>
    </>
  );
};
