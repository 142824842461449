//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { DateTime, Settings } from "luxon";
import {useFetchGiftSources, useDeleteGiftSource, useFetchGiftSourcesHistory} from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, ViewCommandCell, EditCommandCell } from "../../../components/GridComponents.jsx";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";
import { Button } from '@progress/kendo-react-buttons';
import { Badge, BadgeContainer, Loader, Skeleton } from '@progress/kendo-react-indicators';
import { ExcelExport } from '@progress/kendo-react-excel-export';

const StatefullGrid = WithState(Grid);

export const GiftSourcesHistoryGrid = () => {
  const filterOperators = {
    text: [
      {
        text: "grid.filterContainsOperator",
        operator: "contains",
      },
      { text: "grid.filterIsEmptyOperator", operator: "isempty" },
      { text: "grid.filterIsNotEmptyOperator", operator: "isnotempty" },
    ],
    numeric: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterGteOperator", operator: "gte" },
      { text: "grid.filterLteOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    date: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
      { text: "grid.filterAfterOrEqualOperator", operator: "gte" },
      { text: "grid.filterBeforeOrEqualOperator", operator: "lte" },
      { text: "grid.filterIsNullOperator", operator: "isnull" },
      { text: "grid.filterIsNotNullOperator", operator: "isnotnull" },
    ],
    boolean: [
      {
        text: "grid.filterEqOperator",
        operator: "eq",
      },
    ],
  };
  
  const deleteMutation = useDeleteGiftSource();    

  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "createdAt", dir: "asc" }
    ],
    skip: 0,
    take: 10,
  });

  const [gridData, setGridData] = React.useState([]);

  const navigate = useNavigate();
  const _export = React.useRef(null);
  
  const excelExport = () => {    
    if (_export.current !== null) {      
      _export.current.save();
    }
  };

  const enterView = (item) => {
    navigate(`${item.id}`);
  };


  const RecordEditCommandCell = (props) => (
    <EditCommandCell {...props} enterEdit={enterEdit} />
  );
  const RecordViewCommandCell = (props) => (
    <ViewCommandCell {...props} enterView={enterView} />
  );

  const RecordDeleteCommandCell = (props) => (    
    <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
  );  

  const GiftCommandCell = (props) => {
    return (
      <>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          onClick={() => props.enterView(props.dataItem)}
        > <span className="k-icon"></span>          
          Gift Aids
        </button>
      </>
    );
  };

  const RecordGiftAidCommandCell = (props) => (
    <ViewCommandCell {...props} name="GiftAid" enterView={enterGigtAid} />
  );

  const RecordCombinedCommandCell = (props) => {
    const { dataItem } = props;
    const giftAids = dataItem.giftAids;
    const count = Array.isArray(giftAids) ? giftAids.length : 0;
    return (    
      <td className="record-combined-command-cell">
        <BadgeContainer  >
          <GiftCommandCell {...props} enterView={enterGigtAid} />
                {count == 0 && <Badge themeColor="base" style={{color:'red'}} align={{
                      vertical: 'top',
                      horizontal: 'start'
                    }} >
                                *
                            </Badge> }
        </BadgeContainer>
        
        
        {/* <ViewCommandCell {...props} enterView={enterView}  /> */}
        <EditCommandCell {...props} enterEdit={enterEdit} />      
        <DeleteCommandCell {...props} onDelete={ deleteMutation.mutate } />
      </td>
    );
  }
    
    

  const enterGigtAid = (item) => {     
    navigate(`GS-${item.id}/giftaids`);
  }  

  const enterEdit = (item) => {     
    navigate(`GS-${item.id}/edit`);
  }  

  return (
    <>      
     <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10` },            
            { id: "history", text: "History",  iconName: 'clock-arrow-rotate', url: `` },            
          ]} 
        />
       {/* <div style={{margin:'10px 0px'}}>
        <button className="btn btn-primary"  onClick={() => navigate('new') }> New </button>              
      </div>     */}
      <ExcelExport data={gridData.data ?? []} ref={_export}> 
        <StatefullGrid
          apiDataSource={useFetchGiftSourcesHistory}
          filterOperators={filterOperators}
          sortable={true}
          
          resizable={true}
          // data={d => d.map((item) => ({
          //   ...item,
          //   inEdit: item.srsNumber === editID,
          // }))}
          minResizableWidth={30}                        
          dataState={gridDataState}
          gridData={gridData}
          setGridDataState={setGridDataState}
          setGridData={setGridData}
          updateURLSearchParams = {true}        
        > 
        <GridToolbar>       
           <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Left-aligned button */}                
                
                {/* Right-aligned button */}
                <Button
                  title="Export Excel"
                  icon="download"
                  themeColor="secondary"
                  type="button"
                  onClick={excelExport}
                />  
              </div>
          </GridToolbar>
          <GridColumn field="id" title="ID" filterable={true} filter="numeric"  cell={ (props) => {
                return (
                  <td>
                    GS-{props.dataItem.id}
                  </td>
                )}
            } width={200}  />       
          <GridColumn field="name" title="Name" filterable={true} width={200} />     
          <GridColumn field="formalName" title="Formal Name" filterable={true} width={200} />     
          <GridColumn field="type" title="Type" filterable={true}  width={200}  />                   
          <GridColumn field="foundationNumber" title="FoundationNumber" filter="numeric"  filterable={true} width={150} />
          <GridColumn field="fundNumber" title="FoundNumber" filter="numeric"  filterable={true} width={150} />        
          <GridColumn field="amount" title="Amount" filter="numeric"  filterable={true} width={200} />        
          <GridColumn field="departments" title="Departments" filterable={true}  width={200}  />               
          <GridColumn field="description" title="Description"  width={200}  />
          <GridColumn field="criteria" title="Criteria"  width={200}  />
          <GridColumn field="adminNotes" title="Admin Notes" filterable={true}  width={200}  />                                
                  
          <GridColumn field="sourceDocumentURL" title="Source Document URL" />
          <GridColumn field="doesRequireMTF" title="Does Require MTF" />        
          
          

          <GridColumn field="dateEstablished" title="Date Established" filter="date" format="{0:MM/dd/yyyy}"  width={200} />
          <GridColumn field="academicYearAvailableAt" title="Academic Year Available At"   filter="date" format="{0:MM/dd/yyyy}"   width={200} />        
          
          <GridColumn field="createdAt" title="Created At" filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />
          <GridColumn field="modifiedAt" title="Modified At" filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />
          <GridColumn field="modifiedByInfo" title="Modified By Info" filterable={true}  width={200} />                 
          <GridColumn field="validFrom" editable={false} title="Valid From" filterable={false} filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />       
          <GridColumn field="validTo" editable={false} title="Valid To" filterable={false} filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />       
          {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}
          
          {/* <GridColumn cell={RecordGiftAidCommandCell} filterable={false}  width={"120px"}/>        
          <GridColumn cell={RecordEditCommandCell} filterable={false}  width={"100px"}/> */}
          {/* <GridColumn cell={RecordCombinedCommandCell} filterable={false} width={"250px"} /> */}

        </StatefullGrid>
      </ExcelExport>
    </>
  );
};
