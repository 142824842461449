import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchGiftSource } from "../../../services/awardMgmgtService";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { GiftSourceViewComponent } from "../components/GiftSourceViewComponent";
export const GiftSourceView = (props) => {
  const { giftAidId, giftSourceId } = useParams();
  const nav = useNavigate();
  if (!giftSourceId) throw Error("Id not found");
  
  const { data: data, status : status, isSuccess : isSuccess, isError : isError, error: error } = useFetchGiftSource(giftSourceId);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;      

  /* 
  "foundationNumber": 90228,
    "formalFoundationNumber": "E90228",
    "fundNumber": 54541,
    "name": "JULIAN EULE MEMORIAL SCHOLARSHIP FUND",
    "formalName": "JULIAN EULE MEMORIAL SCHOLARSHIP FUND",
    "criteria": "This is a prize for the top grade in Constitutional Law 1. There are five sections; therefore, the prize will be split in five ways.",
    "adminNotes": null,
    "description": "$500 per person ($2,500 total to be distributed)",
    "type": "EndowmentFoundation",
    "departments": "llm_admissions;emmett_institute_on_client_change",
    "sourceDocumentURL": "https://uclaschooloflaw-my.sharepoint.com/:w:/r/personal/ismael_law_ucla_edu/_layouts/15/Doc.aspx?sourcedoc=%7B59E175A4-7358-4AA0-B3B5-3BD86F9A3440%7D&file=UCLA%20School%20of%20Law%20Scholarship%20Report%20Form--EULE.doc&action=default&mobileredirect=true",
    "typeEnum": 0,
    "doesRequireMTF": false,
    "isThankYouLetterNeeded": null,

  */
  return (
    <>         
        
        <div>                                           
          <div className="row"> 
            <NavLink to={`/awardmgmt/giftsources/GS-${giftSourceId}/edit`} style={{ marginRight: '20px' }}>edit</NavLink>          
          </div>

          <GiftSourceViewComponent data={data} />
         
          {/* <div className="row">
            <div className="col">
                <hr />
                <label></label>   
                <div className="value">Last modified by <b>{data.modifiedByUser.firstName}, {data.modifiedByUser.lastName} </b> at {DateTime.fromISO(data.modifiedAt).toLocaleString(DateTime.DATETIME_SHORT)}  </div>
              </div>               
          </div> */}
              

    </div>
      <style>{`              
              label {
                font-weight:bold;
                
              }             
              `}
        </style>
    </>    
  );
};
