import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchGiftSource } from "../../../services/awardMgmgtService";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";


export const GiftSourceViewComponent = ({data}) => {      
  return (
    <>                 
        <div>                                                     
          <div className="row">
            <div className="col-4">
              <div>
                    <label>Gift Source Id: </label>
                    <span className="value">&nbsp; {data.prefixedId} </span>
              </div>                      
              <div>
                  <label>Name</label>
                  <span className="value">&nbsp; {data.name} </span>
              </div>               
              <div>
                <label>Formal Name</label>
                <span className="value">&nbsp; {data.formalName} </span>
              </div>  
              <div>
                  <label>Foundation Number: </label>
                  <span className="value">&nbsp; {data.foundationNumber} </span>
              </div>                      
              <div>
                  <label>Fund Number: </label>
                  <span className="value">&nbsp; {data.fundNumber} </span>
              </div>     
              <div>
                  <label>Type: </label>
                  <span className="value">&nbsp; {data.type?.text} </span>
                </div>  
              <div>
                <label>Criteria</label>
                <span className="value">&nbsp; {data.criteria} </span>
              </div>                        
             </div>           
              <div className="col-4">                                                
                <div>
                  <label>Source Document URL: </label>
                  <span className="value"> &nbsp; <a href={data.sourceDocumentURL}> Document LInk </a> </span>
                </div>   
                <div>
                  <label>Does It Require MTF? </label>
                  <span className="value">&nbsp; {data.doesRequireMTF} </span>
                </div>   
                <div>
                  <label>Does Thank You Letter Needed? </label>
                  <span className="value">&nbsp; {data.isThankYouLetterNeeded} </span>
                </div>
                <div>
                  <label>Departments: </label>
                  <span className="value"> &nbsp;
                  {Array.isArray(data.departments)
                      ? data.departments.map(dept => dept.name).join(', ')
                      : ''}
                  </span>
                </div>
                <div>
                  <label>Description: </label>
                  <span className="value"> &nbsp; {data.description} </span>
                </div>  
              </div>
              <div className="col-4">    
              <div>
                <label>Admin Notes</label>
                <span className="value">&nbsp; {data.adminNotes} </span>
              </div>                                              
                <div>
                  <span>                    
                    Created by <em> <b>{data.createdByUser?.firstName}, {data.createdByUser?.lastName} </b> at {DateTime.fromISO(data.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}                  
                    </em> <br />
                  </span>
                  <span>                   
                    Last modified by  <em> <b>{data.modifiedByUser.firstName}, {data.modifiedByUser.lastName} </b> at {DateTime.fromISO(data.modifiedAt).toLocaleString(DateTime.DATETIME_SHORT)}  
                    </em>                                               
                  </span>                  
                </div>                   
              </div>
          </div>                     
    </div>
      <style>{`              
              label {
                font-weight:bold;
                
              }             
              `}
        </style>
    </>    
  );
};
