import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchGiftAid, useUpdateGiftAid } from "../../../../services/awardMgmgtService";
import ApiStatus from "../../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../../components/MyBreadcrumb";
import { useNavigate } from "react-router-dom";
import { ProjectionsGrid } from "./ProjectionsGrid";
import { NavLink } from "react-router-dom";
import { AwardsGrid } from "./AwardsGrid";
import { CommentComponent } from "../../../../components/CommentComponent";

export const GiftAidView = (props) => {
  const { giftAidId, giftSourceId } = useParams();
  const nav = useNavigate();
  if (!giftAidId) throw Error("Id not found");
  const editRoles = ['Manager', 'Admin'];
  const editClaims = ['LawNET Development'];
  
  const { data: data, status : status, isSuccess : isSuccess, isError : isError, error: error } = useFetchGiftAid(giftAidId);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;      

  return (
    <>
     <MyBreadcrumb breadcrumbItems={[          
            { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources` },
            { id: "giftaids", text: `GS-${giftSourceId}`, url: `/awardmgmt/giftsources/GS-${giftSourceId}/giftaids` },            
            { id: "giftaid", text: data?.name || "Gift Aid" }          
          ]} 
        />
      {/* <GiftAidForm
        data={data}
        eventMutation={eventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      /> */}
 {/* <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
          
          onClick={() =>  nav(`edit`) }
        >
          <span className="k-icon k-i-edit"></span>  
            Edit
          </button>
          <hr /> */}
        <div>                                           
          <div className="row">
            <div className="col-6">
            <NavLink to={`/awardmgmt/giftsources/GS-${giftSourceId}/giftaids/${data.prefixedId}/edit`} style={{ marginRight: '20px' }}>edit</NavLink>          
            <div>
                  <label>Gift Aid Id: </label>
                  <span className="value">&nbsp; {data.prefixedId} </span>
              </div>
              <div>
                <label>Gift Aid Name: </label>   
                <span className="value">&nbsp; {data.name} </span>
              </div>                       
              <div>
                  <label>Gift Aid Type: </label>
                  <span className="value">&nbsp; {data?.type?.text} </span>
              </div>
              <div>
                  <label>Cost Center: </label> 
                  <span className="value">&nbsp; {data.costCenter?.code} - {data.costCenter?.name} </span>
              </div>
              <div>
                  <label>AidId: </label>
                  <span className="value"> &nbsp; {data.aidId} </span>
              </div>
              <div>
                  <label>Account Number: </label>
                  <span className="value"> &nbsp; {data.accountNumber} </span>
              </div>
              <div>
                  <label>Departments: </label> 
                  <span> &nbsp;
                    { Array.isArray(data.departments)
                      ? data.departments.map(dept => dept.name).join(', ')
                      : ''}
                    </span>
              </div>           
              <div>
              <div className="value">Last modified by <b>{data.modifiedByUser.firstName}, {data.modifiedByUser.lastName} </b> at {DateTime.fromISO(data.modifiedAt).toLocaleString(DateTime.DATETIME_SHORT)}  </div>        
              </div>
              <br />                          
            </div>
            <div className="col-6">
              <ProjectionsGrid giftAidId={giftAidId} />
            </div>                                                                                                          
          </div>
          <div className="row">
              <hr />
              <AwardsGrid giftAidId={giftAidId} />
          </div>

          {/* <div className="row">
                      <br />
                      <br />
                      <br />
              <hr />
              <CommentComponent editorId={"EditorSetting_FirstYearPreferenceNew"} editRoles= {editRoles} editClaims={editClaims}  />
          </div> */}

          
              

    </div>
      <style>{`              
              label {
                font-weight:bold;
                
              }             
              `}
        </style>
    </>    
  );
};
