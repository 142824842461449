import * as React from "react";
import { Error } from "@progress/kendo-react-labels";
import { useFetchGiftSource, useUpdateGiftSource } from "../../../services/awardMgmgtService";
import { GiftSourceForm } from "./GiftSourceForm";
import ValidationSummary from "../../../components/ValidationSummary";
import ApiStatus from "../../../components/ApiStatus";
import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { MyBreadcrumb } from "../../../components/MyBreadcrumb";

export const GiftSourceEdit = (props) => {
  const { id, stepId } = useParams();
  if (!id) throw Error("Trigger Event id not found");

  const eventMutation = useUpdateGiftSource(id);
  const { data: data, status : status, isSuccess : isSuccess, isError : isError, error: error } = useFetchGiftSource(id);  

  if (!isSuccess) return <ApiStatus status={status}></ApiStatus>;
  if (!data) return <div> Not found.</div>;      

  return (
    <>
      <MyBreadcrumb breadcrumbItems={[          
              { id: "giftsources", text: "Gift Sources",  iconClass: 'k-i-cart', url: `/awardmgmt/giftsources?sort=createdAt-desc&page=1&pageSize=10` },            
              { id: "giftsource", text: `${data.prefixedId}: ${data.name}`},            
            ]} 
        />
      <GiftSourceForm
        data={data}
        eventMutation={eventMutation}
        isNew={true}
        // submitted={(t) => createTriggerEventMutation.mutate(t)}
      />
    </>
  );
};
