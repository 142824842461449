//import './CourseEvaluations.css';
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { WithState } from "../../../components/withState.js";
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSearchParams, useParams} from "react-router-dom";
import { DateTime, Settings } from "luxon";
import { useCreateProjection, useDeleteProjection, useFetchProjections, useFetchProjectionsForGiftAid, useFetchProjectionsHistory, useUpdateProjection} from "../../../services/awardMgmgtService.js";
import { DeleteCommandCell, EditCommandCell, ViewCommandCell, filterOperators } from "../../../components/GridComponents.jsx";
import { MyCommandCell } from "../components/MyCommandCell.jsx";
import { Button } from '@progress/kendo-react-buttons';
import {YearDropDownCell} from "../components/YearDropDownCell.jsx";
import { useNotification } from "../../../components/notificationContext.js"
import { useQueryClient } from "@tanstack/react-query";
import { v4 as uuidv4 } from 'uuid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { MyBreadcrumb } from "../../../components/MyBreadcrumb.jsx";

const editField = "inEdit";
const CommandCell = (props) => {
  const { edit, remove, add, discard, update, cancel, editField } = props;
  return (
    <MyCommandCell
      {...props}
      edit={edit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );
};

const StatefullGrid = WithState(Grid);

const breadcrumbItems = [{
  id: "gift_sources",
  text: " Gift Sources",
  URL: "/gift_sources",
  iconClass: 'k-i-cart'
}, 
{
  id: "gift_aids",
  text: "Gift Aids"
}];

export const ProjectionsHistoryGrid = () => {
  const { giftSourceId, giftAidId } = useParams();

  const [editID, setEditID] = React.useState(null);
  const createMutation = useCreateProjection();
  const deleteMutation = useDeleteProjection();    
  const updateMutation = useUpdateProjection(giftAidId);
  const { notifications, setNotifications } = useNotification();
  const queryClient = useQueryClient();
  const _export = React.useRef(null);

  const excelExport = () => {    
    if (_export.current !== null) {      
      _export.current.save();
    }
  };

  const rowClick = (event) => {
    //debugger;
    setEditID(event.dataItem.year);
  };
  
  const [gridDataState, setGridDataState] = React.useState({
    sort: [
      { field: "year", dir: "desc" }
    ],
    // filter: {
    //   logic: 'and',
    //   filters: [
    //     { field: 'giftAidId', operator: 'eq', value: giftAidId }
    //   ]
    // },
    skip: 0,
    take: 11,
  });

  
  const [gridData, setGridData] = React.useState([]);
  const [gridDataOrig, setGridDataOrig] = React.useState([]);

  const navigate = useNavigate();

   // modify the data in the store, db etc
   const remove = (dataItem) => {
    const newData = [...deleteMutation.mutate(dataItem)];
    
    // setGridData(
    //   {...gridData, data:gridData.data.map(
    //     item => getId(dataItem.giftAidId,dataItem.year) ? { ...item, inEdit: false} : item
    //   )
    //   }    
    // );
  };
  const add = (dataItem) => {
    dataItem.inEdit = true;
    const newData = createMutation.mutate(dataItem, {
      onSuccess: (_, record) => {
        // setGridData(
        //   {...gridData, data:[data, ...gridData.data]
        //   }    
        // );

        queryClient.invalidateQueries([
          `/awardmgmt/giftaids/${record?.giftAidId}/projections`,
        ]);
        queryClient.invalidateQueries([`awardmgmt/projections`]);
        //nav(`/awardmgmt/giftsources/GS-${}/giftaids/GA-${record?.giftAidId}/projections`);
        setNotifications([
          ...notifications,
          {
            id: uuidv4(),
            style: "success",
            text: `Your changes has been saved.`,
          },
        ]);
        
        // setGridData(
        //   {...gridData, data:gridData.data.map(
        //     item => dataItem.giftAidId + dataItem.year ? { ...item, inEdit: false} : item
        //   )
        //   }    
        // );
        gridDataOrig[getId(dataItem.giftAidId,dataItem.year)] = dataItem;
        return _;
      }
    });

  
  };

  const update = async (dataItem) => {

    const res = await updateMutation.mutateAsync({...dataItem, modifiedAt:  (DateTime.fromJSDate(new Date())).toISO()});
    // dataItem.inEdit = false;
    // const newData = updateItem(dataItem);
    // setData(newData);
    setGridData(
      {...gridData, data:gridData.data.map(
        item => getId(dataItem.giftAidId,dataItem.year) ? { ...item, inEdit: false} : item
      )
      }    
    );
    gridDataOrig[getId(dataItem.giftAidId,dataItem.year)] = dataItem;
  };

  const discard = () => {
    const newData = [...gridData.data];
    newData.splice(0, 1);    
    setGridData(
      {...gridData, data:newData
      }    
    );   
  };

  const getId = (giftAidId, year) =>`giftAidId_${giftAidId}_year_${year}`

  const cancel = (dataItem) => {    
    var origItem = gridDataOrig[getId(dataItem.giftAidId,dataItem.year)];
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.giftAidId === dataItem.giftAidId && item.year === dataItem.year ? { ...origItem, inEdit: false} : item
      )
      }    
    );       
  };

  // const enterView = (item) => {
  //   navigate(`GA-${item.id}`);
  // };

  const enterEdit = (dataItem) => {     
    setGridData(
      {...gridData, data:gridData.data.map(
        item => item.giftAidId === dataItem.giftAidId && item.year === dataItem.year  ? {...item, inEdit: true} : item
      )
      }    
    );
    const idToRow = gridData.data.reduce((acc, item, index) => {;
      acc[getId(item.giftAidId,item.year)] = item;
      return acc;
    }, {});
    setGridDataOrig(idToRow); 
  }  

  const itemChange = (event) => {    
    const newData = {...gridData, data:gridData.data.map(
      item => item.giftAidId === event.dataItem.giftAidId && item.year === event.dataItem.year ? {...item, [event.field || ""]: event.value} : item
    )}    
    setGridData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      giftAidId: Number(giftAidId),
      year: null,
      amount: 0,
      inEdit: true,   
      isNew: true,
      createdAt: (DateTime.fromJSDate(new Date())).toISO(),   
      yearOptions : yearsOptions(20,23)
    };
    setGridData(
      {...gridData, data:[newDataItem, ...gridData.data]
      }    
    );    
  };
  
  const commandCellProps = {
    edit: enterEdit,
    remove: remove,
    add: add,
    discard: discard,
    update: update,
    cancel: cancel,
    editField: editField,
  };

  const yearsOptions = (yearsBack, count) => {
    const startYear = new Date().getFullYear() - yearsBack;
    const existingYears = new Set(gridData.data.map(item => item.year));

  return Array.from({ length: count }, (_, i) => startYear + i)
    .filter(year => !existingYears.has(year))
    .map(year => ({ id: year, text: year }))
    .sort((a, b) => b.id - a.id);
  };

  return (
    <>           
      <MyBreadcrumb breadcrumbItems={[          
                        { id: "projections", text: "Projections",  iconClass: 'k-i-formula-fx', url: `/awardmgmt/projections` },            
                        { id: "projectionsHistory", text: "Projections History",  iconName: 'clock-arrow-rotate', url: `` },            
              ]} 
                    />   
      <div className="row" style={{width:'100%'}}>
        <h5>Yearly Projections</h5>        
      </div>        
      <ExcelExport data={gridData.data ?? []} ref={_export}>
        <StatefullGrid      
          apiDataSource={useFetchProjectionsHistory}      
          filterOperators={filterOperators}
          sortable={true}
          
          resizable={true}
          // data={d => d.map((item) => ({
          //   ...item,
          //   inEdit: item.srsNumber === editID,
          // }))}
          minResizableWidth={30}          
          onItemChange={itemChange}
          editField="inEdit"
          onRowClick={rowClick}

          dataState={gridDataState}
          gridData={gridData}
          setGridDataState={setGridDataState}
          setGridData={setGridData}
          updateURLSearchParams = {true}        
        >         
          <GridToolbar>       
            <div className="export-excel-container" style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            width: "100%",
                                          }}>
              <Button title="Export Excel" style={{float:'right'}}  icon="download" themeColor={'secondary'} type="button" onClick={excelExport}>                
              </Button>
            </div>
          </GridToolbar>        
          {/* <GridColumn field="giftAid.id" title="Gift Aid Id"  editor="numeric" filter="numeric" filterable={true} editable={false} width={200}  
              cell={ (props) => {
                return (
                  <td>
                    GA-{props.dataItem.giftAid.id}
                  </td>
                )}
              }
          />             */}
          <GridColumn field="giftAidId" title="Gift Aid id"  editable={false} filterable={true} filter="numeric" width={"200px"}   />                                                
          <GridColumn field="giftAid.name" title="Gift Aid Name"  editable={false} filterable={true} width={"200px"}   />                                                
          <GridColumn field="giftAid.type" title="Gift Aid Type"  editable={false} filterable={true} width={"200px"}   />                                                
          {/* <GridColumn field="giftAid.giftSource.type" title="Gift Source Type"  editable={false} filterable={true} width={"200px"}   />                                                 */}
          {/* <GridColumn field="giftAid.giftSource.foundationNumber" title="FoundationNumber"  filter="numeric"  editable={false}  filterable={true} width={"150px"}   />                                                 */}
          {/* <GridColumn field="giftAid.giftSource.fundNumber" title="FundNumber"  editable={false} filter="numeric"  filterable={true} width={"150px"}   />                                                 */}
          {/* <GridColumn field="giftAid.costCenterCode" title="Cost Center Code"  editable={false}  filterable={true} width={"130px"}   />                                                 */}
          <GridColumn field="year" title="Year"  editor="numeric" cell={YearDropDownCell} filter="numeric"  filterable={true}  width={130} />    
          {/* <GridColumn field="giftAid.aidId" title="AidId"  editor="numeric" filterable={true} width={130} editable={false}  />             */}
          <GridColumn field="amount" title="Amount"  editor="numeric" format="{0:c}" filter="numeric"  filterable={true}  width={150} />             
          {/* <GridColumn field="modifiedByUser.email" title="Modified By"   filterable={false} width={150} editable={false} />              */}
          <GridColumn field="modifiedAt" editable={false} title="Modified At" filterable={false} filter="date" format="{0:MM/dd/yyyy}" width={"150px"} />
          <GridColumn field="modifiedByInfo" title="Modified By Info" filterable={true}  width={200} />                 
          <GridColumn field="validFrom" editable={false} title="Valid From" filterable={false} filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />       
          <GridColumn field="validTo" editable={false} title="Valid To" filterable={false} filter="date" format="{0:MM/dd/yyyy hh:mm a}" width={200} />       
          {/* <GridColumn field="modifiedBy" title="Modified By" filterable={true} />                                  */}

          {/* <GridColumn cell={RecordCombinedCommandCell} filterable={false}  width={"200px"}/> */}
          {/* <GridColumn filterable={false}
            cell={(props) => <CommandCell {...props} {...commandCellProps} />}
            width="200px"
          /> */}
        </StatefullGrid>
      </ExcelExport>       
    </>
  );
};
